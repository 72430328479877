import { useQuery } from '@tanstack/react-query'
import { statusCheck, getBannerData } from '@/util/queries'
import { useEffect, useState } from 'react'

const Banner = () => {
  const [shouldRefetch, setShouldRefetch] = useState(false)
  const {
    data: status,
    isError: statusError,
    refetch,
  } = useQuery({
    queryKey: ['statusCheck'],
    queryFn: statusCheck,
    retry: false,
  })

  const displayError = statusError || status?.status !== 200

  useEffect(() => {
    if (statusError) {
      setShouldRefetch(true)
    } else {
      setShouldRefetch(false) // Stop refetching if the query succeeds
    }
  }, [statusError])

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>
    if (shouldRefetch) {
      interval = setInterval(() => {
        refetch()
      }, 5000) // Refetch every 5 seconds
    }
    return () => {
      if (interval) clearInterval(interval)
    }
  }, [shouldRefetch, refetch])

  const { data: bannerData, isError: bannerError } = useQuery({
    queryKey: ['getBannerData'],
    queryFn: getBannerData,
    enabled: !!status,
    select: (res) => {
      if (res.data.results) {
        const data = res.data.results.find((result: any) => {
          return checkDate(
            result.properties?.Date?.date?.start,
            result.properties?.Date?.date?.end
          )
        })
        return data?.properties
      }
    },
  })

  const checkDate = (start: string | null, end: string | null) => {
    if (start == null) return false
    if (end == null) return false

    const now = Date.now()
    const startDate = Date.parse(start)
    const endDate = Date.parse(end)

    if (startDate < now && now < endDate) {
      return true
    }
    return false
  }

  const getBanner = () => {
    if (displayError) {
      return (
        <div className="flex items-center gap-x-6 bg-warning px-6 py-2.5">
          <div>
            <p className="text-lg text-black">
              <strong className="font-semibold">
                Scheduled maintenance in progress:&nbsp;
              </strong>
              Some services may be unavailable. Thank you for your patience.
            </p>
          </div>
        </div>
      )
    }

    if (bannerData) {
      return (
        <div
          className={`flex items-center gap-x-6 px-6 py-2.5 ${bannerData.Styles.rich_text[0].plain_text}`}
        >
          <div>
            <p className="text-lg ">
              {bannerData.Text.rich_text[0].plain_text}
            </p>
          </div>
        </div>
      )
    }

    return null
  }

  return <>{getBanner()}</>
}

export default Banner
