import { Merchant } from '@/types/FoodMerchant'
import axios from 'axios'
import { QueryKey } from '@tanstack/react-query'

const apiInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})

export const statusCheck = async () => {
  const response = await apiInstance.get('/status', {
    timeout: 1000,
  })

  return response
}

export const getBannerData = async () => {
  const data = await apiInstance.get('/banner').catch((e) => {
    throw e
  })
  return data
}

export const getMerchantPage = async ({ queryKey }: { queryKey: QueryKey }) => {
  const [_key, id] = queryKey
  const res = await apiInstance.get<Merchant>(`/merchant/${id}`).catch((e) => {
    throw e
  })
  return res.data
}
